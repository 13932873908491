<template>
    <div>
        <div v-if="isLoaded" class="modal-dialog modal-dialog--access block block-mode-loading-refresh">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">{{title}}</h3>
                    <button
                        type="button"
                        class="btn-block-option close-modal"
                        @click="$modal.hide('ModalResetStatusUser')"
                    >
                        ×
                    </button>
                </div>

                <div class="block-content content modal-staff">
                    <div class="workers__desc">
                        {{ text }}
                    </div>

                    <div
                        class="row"
                        v-bind:class="{
                            'area_lock': form.access_all || form.parentRight
                        }"
                    >
                        <div class="col-lg-12 workers workers--second">
                            <form class="workers__col">
                                <div class="workers__head">
                                    <ul class="material-header__nav nav" role="tablist">
                                        <li class="nav-item" v-if="showUsersTab">
                                            <a
                                                id="vue-sel-access-workers-tab"
                                                class="active"
                                            >
                                                Сотрудники

                                                <span class="nav-item__numb">
                                                    {{ data.rightTree.users.length }}
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div
                                            class="tab-pane fade active show"
                                            id="vue-sel-access-workers"
                                            role="tabpanel"
                                            aria-labelledby="access-tab"
                                        >
                                            <div class="workers__list">
                                                <ul class="workers__department department">
                                                    <li
                                                        v-for="(item) in userAllList"
                                                        class="department__item open"
                                                    >
                                                        <div class="department__row">
                                                            <span class="department__row_name">
                                                                {{ item.full_name }}
                                                            </span>

                                                            <label>
                                                                <input
                                                                    type="checkbox" class="access_departament"
                                                                    :data-id="'worker-' + item.id"
                                                                    v-model="item.checked"
                                                                    @change="onResetUser(item)"
                                                                >
                                                                <div class="department__row_button">
                                                                    <i v-if="item.checked"
                                                                       class="fas fa-check"
                                                                    ></i>
                                                                    <i v-else class="fas fa-plus"></i>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="module-set__footer d-flex justify-content-center">
                        <button
                            class="v-btn"
                            type="button"
                            :disabled="userResetList.length === 0"
                            @click="onSubmit"
                        >
                            Сохранить
                        </button>

                        <button
                            class="v-btn"
                            type="button"
                            @click="onResetAll"
                        >
                            Сбросить всем статус
                        </button>

                        <button
                            class="v-btn v-btn--transparent"
                            type="button"
                            @click="$modal.hide('ModalResetStatusUser')"
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner v-else/>
    </div>
</template>

<script>
    import { basicMethods } from './basic_methods';
    import LoadingSpinner from '@/components/LoadingSpinner';

    export default {
        name: 'ModalBindingUpdate',
        mixins: [
            basicMethods
        ],
        props: {
            showUsersTab: {
                required: false,
                default: true
            },
            afterUpdate: {
                type: Function,
                required: false
            },
            permissionSettings: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoaded: false,
                userAllList: [],
                userResetList: [],
                form: {
                    access_all: null,
                    parentRight: null
                }
            };
        },
        components: {
            LoadingSpinner
        },
        async created() {
            await this.loadDefaultData();
            await this.loadBindingData();

            this.userAllList = JSON.parse(JSON.stringify(this.data.rightTree.users));
            this.userAllList = this.userAllList.map(item => {
                item.checked = false
                return item
            })

            this.isLoaded = true;
        },
        methods: {
            onSubmit(e) {
                e.preventDefault();
                this.$modal.hide('ModalResetStatusUser', this.userResetList);
            },

            onResetUser(item) {
                if (item.checked) {
                    this.userResetList.push(item.id);
                } else {
                    this.userResetList = this.userAllList.filter(user => user.id !== item.id);
                }
            },

            onResetAll() {
                const data = this.dataPreparation();

                this.userAllList = this.userAllList.map(item => {
                    item.checked = true;
                    return item;
                });
                this.$modal.hide('ModalResetStatusUser', data.users);
            }
        }
    };
</script>

<style lang="scss">
    .v--modal-overlay[data-modal="ModalResetStatusUser"] {
        .v--modal-box {
            padding: 30px;
            overflow-y: auto;
        }
        .modal-dialog--access .close-modal {
            top: -20px;
            right: -20px;
        }
        .modal-dialog--access.block {
            margin: 0;
        }
        .module-set__footer {
            gap: 20px;
        }
    }
    .modal {
        overflow-y: auto;
    }
    .show-permission {
        display: flex;
        background: rgba(0, 0, 0, 0.2);
    }

    .area_lock {
        opacity: 0.5;
        pointer-events: none;
    }

    .display_flex {
        display: flex !important;
    }

    .is_invalid {
        border-color: #ef5350;
    }

    .modal-dialog--access .workers__col{
        height: 100%;
    }

    .modal-dialog--access.block {
        margin: 0 auto;
    }

    @media (max-width: 660px) {.workers__body
        .workers--second {
            margin-top: 20px;
        }
    }

    .workers__tree {
        height: auto;
        min-height: 250px;
    }

    .workers__col {
        height: 100% !important;
    }

</style>
